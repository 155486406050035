import axios from 'axios';
import tokenService from './tokenService';

export default new class {

    async sendFeedback(feedback, metadata) {

        var url = '/v1/feedbacks?';

        url = url + "lat=" + metadata.lat + "&lon=" + metadata.lon + "&language=" + metadata.language + "&client_version=" + metadata.client_version;

        var params = {
            'system_id': 'hsl_finland',
            'station_id': '050'
        }

        var request = {
            'star_rating': feedback.stars,
            'feedback': feedback.text
        }

        var accessToken = await tokenService.getAuthToken();

        await axios.post(process.env.VUE_APP_API_URL + url, request, {
            params: params,
            headers: {
                'Authorization': accessToken
            }
        });
    }
}