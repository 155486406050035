import axios from 'axios';
import tokenService from './tokenService';

const FORECAST_STORAGE_KEY = "getbikenow.geolocation";

export default new class {

    getCachedData(stationId) {

        var cachedDataStr = localStorage.getItem(FORECAST_STORAGE_KEY + "." + stationId);

        if (cachedDataStr) {

            var cachedData = JSON.parse(cachedDataStr);

            // check if token has expired
            if (new Date() <= Date.parse(cachedData.expiresAt)) {

                // return cached token if its valid
                return cachedData.forecastData;
            }
        }

        return null;
    }

    async loadGeolocationData(address, metadata) {

        var accessToken = await tokenService.getAuthToken();

        var url = '/v1/geocoding?';

        url = url + "lat=" + metadata.lat + "&lon=" + metadata.lon + "&language=" + metadata.language + "&client_version=" + metadata.client_version;

        var response = await axios.get(process.env.VUE_APP_API_URL + url, {
            headers: {
                'Authorization': accessToken
            },
            params: {
                'system_id': 'hsl_finland',
                'address': address
            }
        }).catch(function (error) {
            console.log(error);
        });

        if (response)
            return response.data;

        return null;
    }

    pad(number, length) {

        var str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }

        return str;

    }
}