import axios from 'axios';
import tokenService from './tokenService';

const FORECAST_STORAGE_KEY = "getbikenow.forecasts";

export default new class {

    getCachedData(stationId) {

        var cachedDataStr = localStorage.getItem(FORECAST_STORAGE_KEY + "." + stationId);

        if (cachedDataStr) {

            var cachedData = JSON.parse(cachedDataStr);

            // check if token has expired
            if (new Date() <= Date.parse(cachedData.expiresAt)) {

                // return cached token if its valid
                return cachedData.forecastData;
            }
        }

        return null;
    }

    async loadForecastData(stationId, metadata) {

        var cachedData = this.getCachedData(stationId);

        if (cachedData) {
            return cachedData;
        }

        var accessToken = await tokenService.getAuthToken();

        var url = '/v1/forecasts?';

        url = url + "lat=" + metadata.lat + "&lon=" + metadata.lon + "&language=" + metadata.language + "&client_version=" + metadata.client_version;

        var response = await axios.get(process.env.VUE_APP_API_URL + url, {
            headers: {
                'Authorization': accessToken
            },
            params: {
                'system_id': 'hsl_finland',
                'station_id': stationId
            }
        });

        var now = new Date().toISOString();

        // process predictions and append to data

        var predictions = response.data.station_forecast.predictions.filter(function (obj) {
            if (obj.time < now) {
                return false;
            }
            else {
                return true;
            }
        });


        var that = this;

        predictions.forEach(function (prediction) {
            // Convert time so that also Safari will show local time correctly
            var dateString = prediction.time.replace(/-/g, '/').replace('T', ' ');

            var utcTime = new Date(dateString);

            var offset = utcTime.getTimezoneOffset();

            utcTime.setMinutes(utcTime.getMinutes() - offset);
            prediction.time = utcTime;
            prediction.timeString = that.pad(prediction.time.getHours(), 2) + ":" + that.pad(prediction.time.getMinutes(), 2);
        });

        response.data.station_forecast.predictions = predictions;

        // process hourly trend and append to data

        var hourlyTrends = response.data.station_forecast.hourly_trends.filter(function (obj) {

            var now = new Date();

            var utcTime = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), obj.time, 0, 0));

            obj.time = new Date(utcTime.toISOString());
            obj.timeString = that.pad(obj.time.getHours(), 2) + ":" + that.pad(obj.time.getMinutes(), 2);
            return true;
        });

        response.data.station_forecast.hourly_trends = hourlyTrends;

        // caching

        var acquiredAt = new Date();
        acquiredAt.setSeconds(acquiredAt.getSeconds() + 60); // remove couple of minutes

        var cachedForecastData = {
            forecastData: response.data,
            expiresAt: acquiredAt.toISOString()
        }

        localStorage.setItem(FORECAST_STORAGE_KEY + "." + stationId, JSON.stringify(cachedForecastData));

        return response.data;
    }

    pad(number, length) {

        var str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }

        return str;

    }
}