<template>
  <div>
    <!-- <ul>
      <li v-for="locale in locales" :key="locale" @click="switchLocale(locale)">{{locale}}</li>
    </ul> -->

    <md-button @click="switchFi()" class="md-icon-button md-raised md-accent">
      FI
    </md-button>
    <md-button @click="switchSv()" class="md-icon-button md-raised md-accent">
      SV
    </md-button>
    <md-button @click="switchEn()" class="md-icon-button md-raised md-accent">
      EN
    </md-button>
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      locales: "fi,en,sv".split(","),
    };
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.$store.dispatch("changeLanguage", locale);
      }
    },
    switchFi() {
      this.switchLocale("fi");
      
      this.$gtag.query("event", "page_view", {
        page_path: "/languageChangeFI",
        page_title: "Language change FI",
      });
    },
    switchEn() {
      this.switchLocale("en");
      this.$gtag.query("event", "page_view", {
        page_path: "/languageChangeEN",
        page_title: "Language change EN",
      });
    },
    switchSv() {
      this.switchLocale("sv");
      this.$gtag.query("event", "page_view", {
        page_path: "/languageChangeSV",
        page_title: "Language change SV",
      });
    },
  },
};
</script>