import axios from 'axios';
import qs from 'qs';

const TOKEN_KEY = "TOKEN_KEY";

export default new class {

    async getAuthToken() {

        // check if we have a cached token
        if (localStorage.getItem(TOKEN_KEY)) {

            var tokenData = JSON.parse(localStorage.getItem(TOKEN_KEY));

            // check if token has expired
            if (new Date() <= new Date(tokenData.expiresAt)) {

                // return cached token if its valid
                return tokenData.access_token;
            }
            else {
                console.trace('cached token has expired');
            }
        }

        const LIST_OF_SCOPES = process.env.VUE_APP_COGNITO_SCOPES
        const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;
        const CLIENT_SECRET = process.env.VUE_APP_CLIENT_SECRET;

        var url = "/oauth2/token";

        var reqBody = qs.stringify({
            grant_type: 'client_credentials',
            scope: LIST_OF_SCOPES
        });

        var response = await axios.post(process.env.VUE_APP_COGNITO_URL + url, reqBody, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            auth: {
                username: CLIENT_ID,
                password: CLIENT_SECRET
            }
        });

        // set expiration time to access token data
        var acquiredAt = new Date();
        acquiredAt.setSeconds(acquiredAt.getSeconds() + (response.data.expires_in - 300)); // remove couple of minutes

        response.data.expiresAt = acquiredAt.toISOString();

        // cache the token
        localStorage.setItem(TOKEN_KEY, JSON.stringify(response.data));

        return response.data.access_token;
    }
}