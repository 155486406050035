<template>
  <div style="max-width: 800px;  position: relative;  margin: 0;">
    <autocomplete
      ref="autocompleteStation"
      id="autocompleteStation"
      :search="searchStation"
      :key="stationSearchKey"
      :get-result-value="getResultValue"
      @submit="handleSubmit"
      v-bind:placeholder="$t('stationSearch.placeholder')"
      v-bind:aria-label="$t('stationSearch.placeholder')"
      v-if="stationSearchActive"
    ></autocomplete>
    <autocomplete
      ref="autocompleteAddress"
      id="autocompleteAddress"
      :key="addressSearchKey"
      :search="searchAddress"
      :get-result-value="getResultValueAddress"
      v-bind:placeholder="$t('addressSearch.placeholder')"
      v-bind:aria-label="$t('addressSearch.placeholder')"
      :debounce-time="1000"
      @submit="handleSubmitAddress"
      v-if="!stationSearchActive"
    ></autocomplete>
    <md-button
      @click="clear()"
      v-if="this.value"
      id="clearButton"
      class="md-icon-button md-raised md-accent"
    >
      <md-icon>clear</md-icon>
    </md-button>
    <md-button
      @click="activateAddressSearch()"
      v-if="stationSearchActive && !this.value"
      id="clearButton"
      class="md-icon-button md-raised md-accent"
    >
      <md-icon>map</md-icon>
    </md-button>
    <md-button
      @click="activateStationSearch()"
      v-if="!stationSearchActive && !this.value"
      id="clearButton"
      class="md-icon-button md-raised md-accent"
    >
      <md-icon>pedal_bike</md-icon>
    </md-button>
  </div>
</template>

<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";
import Geolocation from "../services/geolocationService";
import Metadata from "../services/metadataService";

export default {
  name: "StationSearch",
  computed: {
    stationsCount() {
      return this.$store.state.stations.station_count;
    },
    stationNames() {
      return this.$store.state.stations.stations.map(
        (x) => x.name + " (" + x.station_id + ")"
      );
    },
  },
  data: () => ({
    selectedStationName: null,
    selectedStationName2: (val) => {
      this.$store.state.stations.filter((x) => x.name === val);
    },
    value: "",
    stationSearchActive: true,
    searchTerm: "",
    addressSearchKey: 0,
    stationSearchKey: 0,
  }),
  methods: {
    onInputChange() {},
    selectHandler() {},
    clickHandler() {},
    searchStation(input) {
      this.value = input;
      if (input.length < 1) {
        return [];
      }
      if (this.$store.state.stations) {
        var stationHits = this.$store.state.stations.stations.filter(
          (station) => {
            return station.name.toLowerCase().startsWith(input.toLowerCase());
          }
        );

        return stationHits;
      }
    },
    activateStationSearch() {
      this.stationSearchActive = true;
      this.addressSearchKey += 1;
      this.stationSearchKey += 1;
    },
    activateAddressSearch() {
      this.stationSearchActive = false;
      this.addressSearchKey += 1;
      this.stationSearchKey += 1;
    },
    getResultValue(result) {
      // return result.name + " (" + result.station_id + ")";
      return result.name;
    },
    handleSubmit(val) {
      this.$store.dispatch("selectedStationChange", val);
    },
    async searchAddress(input) {
      this.value = input;
      if (input.length < 6) {
        return [];
      }

      var metadata = Metadata.getMetadata(this.$store);
      var geoLocResult = await Geolocation.loadGeolocationData(input, metadata);

      if (geoLocResult == null) {
        return [
          {
            name: this.$t("noSearchResults"),
          },
        ];
      }

      return [
        { name: geoLocResult.formatted_address, coord: geoLocResult.location },
      ];
    },
    getResultValueAddress(result) {
      return result.name;
    },
    handleSubmitAddress(val) {
      if (val && val.coord) {
        this.$store.dispatch("userLocationSet", {
          lat: val.coord.lat,
          lon: val.coord.lon,
        });
        this.$store.dispatch("coordinatesChange", {
          lat: val.coord.lat,
          lon: val.coord.lon,
        });
      }
    },
    clear() {
      this.value = "";
      if (this.$refs.autocompleteStation) {
        this.$refs.autocompleteStation.value = "";
      }
      if (this.$refs.autocompleteAddress) {
        this.$refs.autocompleteAddress.value = "";
      }
    },
  },
  components: {
    Autocomplete,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.search {
  max-width: 500px;
}

ul.autocomplete-result-list {
  z-index: 40000000 !important;
}

ul.autocomplete-result-list > li {
  z-index: 40000000 !important;
  color: black;
}

ul.autocomplete-result-list > li > span {
  z-index: 40000000 !important;
}

.autocomplete-result {
  color: black !important;
  text-align: left;
}

#clearButton {
  display: block;
  position: absolute;
  z-index: 2;
  color: black;
  top: 50%;
  right: 0;
  border: none;
  background: #40c3d6;
  transform: translate(calc(100% + 8px), -50%);
  margin-right: 0.2em;
}
</style>