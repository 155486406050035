
export default new class {

    getMetadata(state) {
        return {
            lat: state.userLocation ? state.userLocation.lat : "",
            lon: state.userLocation ? state.userLocation.lon : "",
            client_version: state.packageVersion,
            language: state.uiLanguage
        }
    }
}