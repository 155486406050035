import axios from 'axios';
// import qs from 'qs';
import tokenService from './tokenService';

const STATIONS_STORAGE_KEY = "getbikenow.stations";

export default new class {

    getCachedData() {

        var cachedDataStr = localStorage.getItem(STATIONS_STORAGE_KEY);

        if (cachedDataStr) {

            var cachedData = JSON.parse(cachedDataStr);

            // check if token has expired
            if (new Date() <= Date.parse(cachedData.expiresAt)) {

                // return cached token if its valid
                return cachedData.stationData.data;
            }
        }

        return null;
    }

    async loadStationData(metadata) {

        var cachedData = this.getCachedData();

        if (cachedData) {
            return cachedData;            
        }

        var accessToken = await tokenService.getAuthToken();

        var url = '/v1/stations?';

        url = url + "lat=" + metadata.lat + "&lon=" + metadata.lon + "&language=" + metadata.language + "&client_version=" + metadata.client_version;

        var stationData = await axios.get(process.env.VUE_APP_API_URL + url, {
            headers: {
                'Authorization': accessToken,
                'Content-Type': 'application/json'
            },
            params: { 'system_id': 'hsl_finland' }
        });

        // set expiration time to access token data
        var acquiredAt = new Date();
        acquiredAt.setSeconds(acquiredAt.getSeconds() + 3600); // remove couple of minutes

        var cachedStationData = {
            stationData: stationData.data,
            expiresAt: acquiredAt.toISOString()
        }

        localStorage.setItem(STATIONS_STORAGE_KEY, JSON.stringify(cachedStationData));

        return stationData.data;
    }
}