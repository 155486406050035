import Vue from 'vue'
import Vuex from 'vuex'
import store from './store/index'
import App from './App.vue'
import './registerServiceWorker'

import { MdToolbar, MdDrawer, MdButton, MdIcon, MdList, MdAutocomplete, MdField, MdMenu, MdDivider, MdSubheader, MdApp, MdContent, MdCard, MdDialog, MdDialogAlert, MdTabs, MdSnackbar } from "vue-material/dist/components";
import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/default.css'
import './styles/aavista.scss'
import './styles/map.scss'
import router from './router'
// import VueAnalytics from 'vue-analytics';
//import VueAnalytics from 'vue-ua'
import VueGtag from "vue-gtag";

import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import i18n from './i18n'

import axios from 'axios';

Vue.config.productionTip = false

Vue.use(Vuex);

Vue.use(VueFilterDateFormat);
Vue.use(VueFilterDateParse);

Vue.use(MdToolbar)
Vue.use(MdDrawer)
Vue.use(MdButton)
Vue.use(MdIcon)
Vue.use(MdList)
Vue.use(MdAutocomplete)
Vue.use(MdField)
Vue.use(MdMenu)
Vue.use(MdDivider)
Vue.use(MdSubheader)
Vue.use(MdApp)
Vue.use(MdContent)
Vue.use(MdCard)
Vue.use(Autocomplete)
Vue.use(MdDialog)
Vue.use(MdDialogAlert)
Vue.use(MdTabs)
Vue.use(MdSnackbar)

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA }
}, router);

// Vue.use(VueAnalytics, {
//   id: 'G-VWNG52L6T9',
//   debug: {
//     enabled: false, // default value
//     trace: false, // default value
//     sendHitTask: true // default value
//   },
//   router
// });

// Vue.use(VueAnalytics, {
//   // [Required] The name of your app as specified in Google Analytics.
//   appName: 'Find Bike Now',
//   // [Required] The version of your app.
//   appVersion: process.env.PACKAGE_VERSION,
//   // [Required] Your Google Analytics tracking ID.
//   trackingId: 'G-9YB9T1PS7D',
//   // If you're using vue-router, pass the router instance here.
//   vueRouter: router,

//   // // Global Dimensions and Metrics can optionally be specified.
//   // globalDimensions: [
//   //   { dimension: 1, value: 'FirstDimension' },
//   //   { dimension: 2, value: 'SecondDimension' }
//   //   // Because websites are only 2D, obviously. WebGL? What's that?
//   // ],

//   // globalMetrics: [
//   //   { metric: 1, value: 'MyMetricValue' },
//   //   { metric: 2, value: 'AnotherMetricValue' }
//   // ]
// })

Vue.prototype.$http = axios

new Vue({
  router,
  store,
  render: h => h(App),
  i18n,

  async created() {

    if (localStorage.getItem("userSeenWelcomeScreen")) {
      this.$store.dispatch('showWelcomeScreen', false);
    }
    else {
      this.$store.dispatch('showWelcomeScreen', true);
    }

    if (localStorage.getItem("uiLanguage")) {
      this.$i18n.locale = localStorage.getItem("uiLanguage");
      this.$store.dispatch('changeLanguage', localStorage.getItem("uiLanguage"));
    } else {
      this.$i18n.locale = 'fi';
      this.$store.dispatch('changeLanguage', 'fi');
    }

    if (localStorage.getItem("showQuestionnaire")) {
      this.$store.dispatch('ignoreQuestionnaire');
    }

    await this.$store.dispatch('loadStations');

    // navigator.geolocation.getCurrentPosition((position) => {
    //   this.$store.dispatch('coordinatesChange', { lat: position.coords.latitude, lon: position.coords.longitude });
    //   this.$store.dispatch('userLocationSet', { lat: position.coords.latitude, lon: position.coords.longitude });
    // }, () => {
    //   this.$store.dispatch('coordinatesChange', { lat: 60.1698, lon: 24.9481 });
    // });
  }
}).$mount('#app')
