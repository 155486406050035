<template>
  <div id="app">
    <div class="spinner" v-if="loading"></div>
    <md-app>
      <md-app-toolbar class="md-primary">
        <div class="md-toolbar-row">
          <div class="md-toolbar-section-start">
            <md-button class="md-icon-button" @click="showNavigation = true">
              <md-icon>menu</md-icon>
            </md-button>
            <StationSearch v-if="$route.meta.showSearch" />
          </div>
        </div>
      </md-app-toolbar>

      <md-app-drawer :md-active.sync="showNavigation">
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="md-title md-title" style="color:#2097B7;">{{ $t("appName") }}</span>
          <span
            class="md-caption md-toolbar-section-end"
            style="text-align:right;"
          >{{ $store.getters.appVersion }}</span>
        </md-toolbar>
        <md-divider />
        <md-list>
          <md-list-item>
            <md-icon>pedal_bike</md-icon>
            <span class="md-list-item-text" @click="showNavigation = false">
              <router-link to="/map">{{ $t("findBikes") }}</router-link>
            </span>
          </md-list-item>

          <md-list-item>
            <md-icon>info</md-icon>
            <span class="md-list-item-text" @click="showNavigation = false">
              <router-link to="/about">{{ $t("about") }}</router-link>
            </span>
          </md-list-item>

          <!-- <md-list-item>
            <md-icon>call</md-icon>
            <span class="md-list-item-text" @click="showNavigation = false">
              <router-link to="/contact">Contact</router-link>
            </span>
          </md-list-item>-->

          <md-list-item>
            <md-icon>feedback</md-icon>
            <span class="md-list-item-text" @click="showNavigation = false">
              <router-link to="/feedback">{{ $t("feedback") }}</router-link>
            </span>
          </md-list-item>
          <!-- <md-list-item>            
            <LanguageSwitcher />
          </md-list-item>-->
        </md-list>
        <md-divider />
        <LanguageSwitcher style="padding:1em; text-align:left;" />
        <md-divider />
        <p style="max-height:75px; text-align:left !important; bottom:0;">
          <img
            src="./assets/Aavista_logo.png"
            alt="Aavista Oy"
            style="max-height:75px; text-align:left !important;"
          />
        </p>
      </md-app-drawer>

      <md-app-content>
        <router-view />        
        <div style="left:1em; bottom:1em; position:absolute; z-index:5000000">
          <md-button @click="locateUser()" class="md-icon-button md-raised md-accent">
            <md-icon>location_on</md-icon>
          </md-button>
          <md-button @click="refreshData()" class="md-icon-button md-raised md-accent">
            <md-icon>refresh</md-icon>
          </md-button>
        </div>        
        <md-dialog :md-active.sync="showDialog" :md-fullscreen="full">
          <md-dialog-title>{{ $t("welcomeDialog.Title") }}</md-dialog-title>
          <md-dialog-content class="md-scrollbar">
            {{ $t("welcomeDialog.Para1") }}
            <br />
            <br />
<!-- REMOVED FOR NOW
            {{ $t("welcomeDialog.Para2") }}
            <br />
            <br />
            {{ $t("welcomeDialog.Para3") }}
-->
          </md-dialog-content>
          <md-dialog-actions>
            <md-button
              class="md-primary"
              @click="hideWelcome"
            >{{ $t("welcomeDialog.CloseButtonTitle") }}</md-button>
          </md-dialog-actions>
        </md-dialog>

        <md-snackbar :md-duration="Infinity" :md-active.sync="updateExists" md-persistent>
          <span>{{ $t("updateAvailable.header") }}</span>
          <md-button class="md-primary" @click="refreshApp">{{ $t("updateAvailable.updateNow") }}</md-button>
        </md-snackbar>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import StationSearch from "./components/StationSearch";
import LanguageSwitcher from "./components/LanguageSwitcher";
import update from "./mixins/update";

export default {
  name: "AavistaCity.ForecastUI",
  data: () => ({
    showNavigation: false,
    showSidepanel: false,
    full: false,
  }),
  mixins: [update],
  components: {
    StationSearch,
    LanguageSwitcher,
  },
  computed: {
    showDialog() {
      return this.$store.getters.showWelcomeScreen;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  methods: {
    hideWelcome: function () {
      this.$store.dispatch("showWelcomeScreen", false);
    },
    refreshData: async function () {
      this.$gtag.query("event", "page_view", {
        page_path: "/refreshData",
        page_title: "Refresh Data",
      });

      await this.$store.dispatch("loadStations");
    },
    locateUser: function () {
      this.$gtag.query("event", "page_view", {
        page_path: "/locateUser",
        page_title: "Locate User",
      });

      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.$store.dispatch("coordinatesChange", {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
          this.$store.dispatch("userLocationSet", {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        () => {
          this.$store.dispatch("coordinatesChange", {
            lat: 60.1698,
            lon: 24.9481,
          });
        }
      );
    },
  },
};
</script>

<style lang="scss">
#app,
.md-app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html, body, #app, .md-app, .md-app-content /*, and all other map parent selectors*/ {
  height: 100%;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0 !important;
}
.spinner {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.spinner:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
}

/* :not(:required) hides these rules from IE9 and below */
.spinner:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.spinner:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 5px solid #40c3d6;
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
