import stationService from '../services/stationService';
import forecastService from '../services/forecastService';
import feedbackService from '../services/feedbackService';
import metadataService from '../services/metadataService';
import Vue from 'vue';
import Vuex from 'vuex';
import L from "leaflet";
Vue.use(Vuex);

const UPDATE_STATIONS = 'UPDATE_STATIONS';
const SET_LOADING = 'SET_LOADING';
const STATION_CHANGE = 'STATION_CHANGE';
const COORDINATES_CHANGE = "COORDINATES_CHANGE";
const INITIAL_LOAD_DONE = "INITIAL_LOAD_DONE";
const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
const FEEDBACK_SENT = "FEEDBACK_SENT";
const USER_LOCATION_SET = "USER_LOCATION_SET";
const SHOW_QUESTIONNAIRE = "SHOW_QUESTIONNAIRE";

const store = new Vuex.Store({
    state: {
        stations: null,
        loading: false,
        selectedStation: null,
        center: null,
        showWelcomeScreen: true,
        uiLanguage: 'fi',
        feedbackSent: false,
        userLocation: null,
        packageVersion: process.env.PACKAGE_VERSION || '0',
        questionnaireVisible: true
    },
    mutations: {
        [UPDATE_STATIONS](state, stations) {
            if (stations && stations.stations) {
                stations.stations.forEach(element => {
                    // element = { ...element, latLng: L.latLng(element.lat, element.lon) };
                    element.latLng = L.latLng(element.lat, element.lon);
                });

                Vue.set(state, 'stations', stations);
            }
            else {
                state.stations = [];
            }

        }, [SET_LOADING](state, loading) {
            state.loading = loading;
        }, [STATION_CHANGE](state, station) {
            state.selectedStation = station;
        }, [COORDINATES_CHANGE](state, coords) {
            state.center = { lat: coords.lat, lon: coords.lon };
        }, [INITIAL_LOAD_DONE](state, showWelcomeScreen) {
            state.showWelcomeScreen = showWelcomeScreen;
        }, [CHANGE_LANGUAGE](state, newLanguage) {
            state.uiLanguage = newLanguage;
        }, [FEEDBACK_SENT](state) {
            state.feedbackSent = true;
        }, [USER_LOCATION_SET](state, coords) {
            state.userLocation = { lat: coords.lat, lon: coords.lon };
        }, [SHOW_QUESTIONNAIRE](state) {
            state.questionnaireVisible = false;
        }
    },
    getters: {
        stations(state) {
            if (state.stations) {
                return state.stations
            }
            else {
                return { stations: [], stationsCount: 0 };
            }
        },
        center(state) {
            if (state.center) {
                return state.center
            }
            return {
                lat: 60.1698,
                lon: 24.9481
            };
        },
        selectedStation(state) {
            return state.selectedStation
        },
        showWelcomeScreen(state) {
            return state.showWelcomeScreen
        },
        uiLanguage(state) {
            return state.uiLanguage
        },
        loading(state) {
            return state.loading;
        },
        appVersion: (state) => {
            return state.packageVersion
        },
        questionnaireVisible: (state)=>{
            return state.questionnaireVisible;
        }
    },
    actions: {
        async loadStations({ commit, state }) {
            commit(SET_LOADING, true);
            try {
                var metadata = metadataService.getMetadata(state);

                var stationData = await stationService.loadStationData(metadata);

                commit(UPDATE_STATIONS, stationData);
                commit(SET_LOADING, false);
            } catch (error) {
                console.log(error);
            }
        },
        async selectedStationChange({ commit, state }, station) {
            if(station == null){
                return;
            }
            commit(SET_LOADING, true);
            try {
                try {
                    var metadata = metadataService.getMetadata(state);

                    var forecastData = await forecastService.loadForecastData(station.station_id, metadata);
                    station.forecast = forecastData;

                    station.forecast.nearby_stations.forEach((nearbyStation) => {
                        var matchingStation = state.stations.stations.filter(station => station.station_id === nearbyStation.station_id);
                        if (matchingStation.length === 1) {
                            nearbyStation.num_bikes_available = matchingStation[0].num_bikes_available
                        }
                    });
                } catch {
                    station.forecast = null;
                }

                commit(STATION_CHANGE, station);
                commit(COORDINATES_CHANGE, { lat: station.lat, lon: station.lon });
            } catch (error) {
                console.log(error);
            } finally {
                commit(SET_LOADING, false);
            }
        },
        coordinatesChange({ commit }, coords) {
            commit(COORDINATES_CHANGE, coords)
        },
        userLocationSet({ commit }, coords) {
            commit(USER_LOCATION_SET, coords)
        },
        showWelcomeScreen({ commit }, userSeenWelcomeScreen) {

            localStorage.setItem("userSeenWelcomeScreen", userSeenWelcomeScreen);

            commit(INITIAL_LOAD_DONE, userSeenWelcomeScreen)
        },
        changeLanguage({ commit }, newLanguage) {
            localStorage.setItem("uiLanguage", newLanguage);
            commit(CHANGE_LANGUAGE, newLanguage)
        },
        async sendFeedback({ commit, state }, feedback) {

            var metadata = metadataService.getMetadata(state);

            await feedbackService.sendFeedback(feedback, metadata);
            commit(FEEDBACK_SENT)
        },
        ignoreQuestionnaire({ commit }, dontShowAgain) {
            if(dontShowAgain)
                localStorage.setItem("showQuestionnaire", false);
            commit(SHOW_QUESTIONNAIRE)
        },
    }
})

export default store;